import AWS from 'aws-sdk';

AWS.config.update({
  accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
  secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
  region: process.env.REACT_APP_AWS_REGION,
});



export const dynamodb = new AWS.DynamoDB.DocumentClient();

export const s3 = new AWS.S3();

const params = {
  Bucket: 'your-bucket-name',
  Key: 'object-key',
};

s3.getObject(params, (err, data) => {
  if (err) {
    console.error('Error downloading object from S3:', err);
  } else {
    console.log('Object data:', data.Body.toString());
  }
});