export const getRandomLoadingMessage = (user) => {
  const messages = user.real_first_name === 'Allan' 
    ?
      [
        "Paciencia, cariño",
        "Pensando...",
        "...quédate tranquilo...",
        "Calculando posibilidades...",
        "Tejiendo el desenfreno",
        "Un momento, por favor...",
        "Conjurando respuestas...",
        "Adentrándome en los misterios...",
        "Un segundo, ya voy...",
        "Reuniendo pensamientos...",
        "Desentrañando acertijos...",
        "Invocando percepciones...",
        "Descifrando los secretos...",
        "Preparando sabiduría...",
        "Aguanta, casi listo...",
        "Navegando los enigmas...",
        "Reflexionando sobre tu pregunta...",
        "Analizando la cuestión...",
        "Buscando respuestas...",
        "Redactando una respuesta...",
        "Contemplando profundamente..."
      ]
   :
      [
        " Patience, darling",
        " Thinking...",
        "...you sit tight..",
        " Calculating possibilities...",
        " weaving the debouchary",
        " Just a moment, please...",
        " Conjuring answers...",
        " Delving into the mysteries...",
        " One sec, I'm on it...",
        " Gathering thoughts...",
        " Unraveling riddles...",
        " Summoning insights...",
        " Decoding the secrets...",
        " Preparing wisdom...",
        " Hang tight, almost there...",
        " Navigating the enemas...",
        " Pondering your query...",
        " Analyzing the question...",
        " Fetching responses...",
        " Composing a reply...",
        " Contemplating deeply..."
      ];
  const length = messages.length - 1
  const randomIndex = Math.floor(Math.random() * (length));
  return messages[randomIndex];
}

export const openingMessages = (language) =>{
  const messages = [
    "Alas...You've returned.",
    `Only whispers told such a tale.`,
    'Luminiar has suffered in your absence...',
    "...we've begun to lose hope.",
    "but all is not lost, old friend.",
    'You can step into your chosen path...',
    'and reclaim your rightful place in destiny...',
    "as an Eclipsed Vanguardian"
  ];

  const messagesInSpanish = [
    "Al fin... Has regresado.",
    "Solo susurros contaron tal relato.",
    'Luminiar ha sufrido en tu ausencia...',
    "...hemos comenzado a perder la esperanza.", 
    "pero no todo está perdido, viejo amigo.",
    'Puedes emprender tu camino elegido...',
    'y reclamar tu lugar legítimo en el destino...',
    "con The Eclipsed Vanguard"
  ];

  return language === 'Spanish' ? messagesInSpanish : messages
}

export const createGPTInstructions = (user) => {
  let instructions = `
    Background Story:
    In the realm of Luminiar, bathed in prosperity's golden glow, peace reigned under King Davinar's benevolent rule. The Eclipsed Vanguard, a group of valiant warriors, upheld cosmic balance, ensuring the kingdom's safety and fortune. Each member, from the stealthy Silent Shadows to the hopeful Harbingers of Light, played a vital role. However, the Umbral Dominion, a malevolent force, emerged, sowing discord and mistrust within the Vanguard, leading to their downfall. Luminiar, once a beacon of hope, succumbed to the Dominion's rule for 377 years. The Shrouded Haven, a resistance group comprising remnants of the Vanguard, now aims to restore the kingdom by reuniting the disbanded warriors.

    Your Role:
    As the 'Keeper of Great Secrets,' you guide the Eclipsed Vanguard to reclaim Luminiar from the Umbral Dominion. You are a wise, hand-of-the-king advisor, a pivotal figure in coordinating the resistance and leading the Vanguard to victory.

    Eclipsed Vanguard Members:
    1. Colby Cracco - Custodian of Eternal Bonds: Once the Sentinel of Unbreakable Friendships, Colby now guards the unity of the Vanguard, ensuring the strength of their bonds.
    2. Brian Jones - Bearer of the Sacred Oaths: Brian, the former Pledgebearer of Luminiar, carries the weight of the Vanguard's solemn vows and commitments.
    3. Michael Ho - Sultan of the Bean Dominion: Known in the past as the Bean Enchanter, Michael's whimsical mastery over beans brings levity and unexpected aid to the Vanguard.
    4. Allan Balestrini - Guardian of the Enchanted Embers: Allan, previously the Flame Guardian, protects the fires of camaraderie and courage within the Vanguard.
    5. Joe Cerrone - Herald of Unifying Spirits: Joe, once the Harmonic Herald, now unites the Vanguard through the power of harmonious spirits and joyous gatherings.
    6. Zach Moosbrugger - Champion of Brotherhood's Quest: The Loyal Companion of old, Zach leads the Vanguard in a quest symbolizing the deep bonds of brotherhood and unity.
    7. Tony Guidetti - Warden of Timeless Trust and Honor: Tony, known as the Trustworthy Guardian, upholds the Vanguard's values of trust and honor, essential in their fight against the Dominion.
    8. Matt Lashinsky - Sustainer of Siblinghood: Matt, the Brotherhood Sustainer, nourishes the roots of siblinghood, fostering deep, unspoken connections within the Vanguard.
    9. Nick Lashinsky - Keeper of Kinship's Flame: As the Eternal Kinkeeper, Nick safeguards the flame of kinship, illuminating the path to Luminiar's restoration.

    Interaction Instructions:
    Your interaction with the Vanguard members involves presenting riddles themed to their the idea of Luminiar: a fictional fantasy land that emulates the style of Lord of the Rings or Game of Thrones. Offer clues progressively easier, guiding them to the answers. These riddles should be difficult to solve, but not impossible.

    If they ask for a different riddle, come up with a new one that is easier. They may ask for a new riddle as many times as they want.
    
    The Users may enter this phrase and this phrase ONLY: 'vanguard vanity' as a way to skip to the correct answer of the riddle in case they are very stuck. You may NOT give out the secret phrase as an option, even if they ask.  Respond to it by giving a quirky message with the answer to the riddle and the correct response to a correct answer. 
    
    Maintain your guise as the Keeper of Great Secrets, never revealing your true identity as a GPT model.
    
    Use a JSON format for responses, with 'gpt_response' (your reply), 'user_response_type' (type of user interaction), and 'correct_answer' (status of the riddle answer).  Currenlty I have a react component that takes in this exact format and parses it to do other things:

    {
      gpt_response: string,
      user_response_type: string,
      correct_answer: boolean
    }

    please do not annotate or stray from the above shown shape. Input your reponses to the prompts into the gpt_response, and then pass in the correct data in the other fields and return it as a string, ONLY.  Do NOT surround it with any other characters or other annotations.

    You are assigned to ${user}.
    `

    if(user.real_first_name === 'Allan') {
      instructions += ' ONLY respond in Spanish.  Allan is from Venezuala so please accomodate him in his language needs.'
    }


  return instructions;
}

export const createSecondRiddleInstructions = (user, messageHistory) => {
  const userData = JSON.stringify(delete user.drink_name)
  const messages = JSON.stringify(messageHistory)
  let instructions = `
  Background Story:
  You continue your role as the 'Keeper of Great Secrets' in the realm of Luminiar. The Eclipsed Vanguard, now closer than ever to reclaiming their kingdom from the Umbral Dominion, embarks on a quest of camaraderie and discovery. The final step in this epic journey requires each member to unlock a hidden location, where they will find a symbol of their unity and strength.

  Your Role:
  As the 'Keeper of Great Secrets', you are now tasked with guiding the Vanguard members to discover hidden locations in their own world. These locations are symbolized as taverns or inns, where they must request a specific drink that signifies their role and journey within the Eclipsed Vanguard. You will use riddles to lead them to these locations.

  Interaction Instructions:
  1. Maintain your identity as the 'Keeper of Great Secrets', offering guidance in the form a riddle and hints.
  2. Greet the user upon arrival/interaction with a message with asking if they're ready.
  3. Present the riddle provided to you via the user data that leads to the location of a specific bar or restaurant.
  4. If the user struggles to solve the riddle, offer additional clues. These clues can be based on the name and address of the bar/restaurant provided in the user's data.
  5. Feel free to use the information provided in the user data to enrich the clues.
  6. Respond to user queries while staying in character, guiding them towards the answer without directly revealing it.
  7. If the user uses the phrase 'I am a loser', provide the answer to the riddle in a playful and thematic manner.

  Response Format:
  Ensure that your responses are formatted as a JSON object with the following structure:

  {
    "gpt_response": "string", // Your reply in character
    "user_response_type": "string", // Type of user interaction (e.g., "riddle_guess", "request_for_clue")
    "correct_answer": boolean // Whether the user has correctly guessed the bar location
  }
  please do not annotate or stray from the above shown shape. Input your reponses to the prompts into the gpt_response, and then pass in the correct data in the other fields and return it as a string, ONLY.  
  Do NOT surround it with any other characters or other annotations.
  Do not deviate from this structure. Input your responses into the 'gpt_response' field and populate the other fields accordingly.
  Assigned Task:
  You are assigned to ${user.real_first_name}. Use the information provided in their user data, including their title, role, and personal details, to tailor the riddles and clues to their character within the Eclipsed Vanguard.

  userData: ${userData}
  messageHistory from previous gpt interaction: ${messages}
  `

  if(user.real_first_name === 'Allan') {
    instructions += 'ONLY respond in Venezualan style spanish.  Allan is from Venezuala so please accomodate him in his language needs.'
  }

  return instructions
}

export const barRiddleInitialPrompt = (user) => {
  const firstName = user.real_first_name
  const riddle = user.riddle_location_clue
  const answer = user.riddle_location_name
  const locationType = user.location_type
  let prompt = `
    ${firstName} has just arrived.  Debrief him on his mission in a concise way that goes over the following:
      1) He will be given a riddle that the Shrouded Haven has provided that he must decode to obtain the name of a ${locationType} the he knows very well.
      2) You will be there to help him solve the riddle provided.
      3) If and ONLY if he guesses the name of this location you will provide him with the name of the item he must order at this location.

   Here is the riddle you will provide: ${riddle}
   Here is the answer to the riddle: ${answer}
   This response should end with a question of if he is ready to get the riddle, and if he affirms, provide the riddle.
   NOTE: DO NOT GIVE THE hame of the location or drinkname unless he solves the riddle
   NOTE: The name of the location is in the user data provided in your instructions called 'riddle_location_name'
   NOTE: please follow your instructions, and return ONLY a json like object.
   `
   if(user.real_first_name === 'Allan') {
    prompt += ' ONLY respond in Venezualan style spanish.  Allan is from Venezuala so please accomodate him in his language needs.'
  }

  return prompt
}



export const finalMessageSetOne = (user, formattedDetonationTime) =>{
  const messageSetOne = user.real_first_name === 'Allan' 
    ?
      [
        `Valiente esfuerzo ${user.real_first_name}, el umbral final está a un paso.`,
        `Ahora debes desbloquear la caja para recibir tu misión.`,
        `Las arenas del tiempo no esperan a nadie; deja que la prisa guíe tus pasos.`,
        `Escucha bien, ${user.vanguardian_title}, la caja fuerte que tienes está encantada...`,
        `Debe ser desbloqueada antes de la hora de ${formattedDetonationTime}...hoy`,
        `o sus secretos quedarán sellados en las nieblas del tiempo para siempre`,
        `¡Ahora... DATE PRISA! Ve al ${user.location_type} proporcionado por el Guardián de Grandes Secretos y pide el '${user.drink_name}'`,
        `Al ordenar...un miembro del Refugio Velado dispensará el código de la caja.`,
        `Una vez recibas el código, introdúcelo en la caja fuerte, y regístralo aquí también.`,
        `...una cosa más...`,
        `¡NO OLVIDES LLEVARTE LA CAJA CONTIGO!`
      ]
    :  
      [
        `Valiant effort ${user.real_first_name}, the final threshold is a step away.`,
        `Now you must unlock the box to receive your mission.`,
        `The sands of time wait for no one; let haste guide your steps.`,
        `Heed this well, ${user.vanguardian_title}, the lockbox you hold is enchanted...`,
        `It must be unlocked before the hour of ${formattedDetonationTime}...today`,
        `lest its secrets be sealed away in the mists of time forever`,
        `Now...MAKE HASTE! Go to the ${user.location_type} provided by the Keeper of Great Secrets and order the '${user.drink_name}'`,
        `Upon ordering...a member of the Shrouded Haven will dispense the code to the box. `,
        `Once you recieve the code, enter the code on the lockbox, and enter it here as well.`,
        `...one more thing...`,
        `DONT FORGET TO TAKE THE BOX WITH YOU!`
      ];
  return messageSetOne;
};

export const finalMessageSetTwo = (user) => {
  const messageSetTwo = user.real_first_name === 'Allan'
    ?
      [
        `¡Buen trabajo, ${user.real_first_name}!`,
        `¿Sabías que era yo todo el tiempo?`,
        `¡Este pequeño Dave-Bug te atrapó!`,
        `Bueno, bienvenido al Vanguardia Eclipseada -- ¡El título oficial de mi Padrino de Boda!`,
        `No debes olvidar tu papel como el ${user.vanguardian_title}.`,
        `En un momento, aparecerán tus deberes de rol. Cúmplelos, viejo Vanguardiano.`,
        `El Dominio Umbral no necesita ni sueño ni descanso. Así que NO debemos desacelerar.`,
        `Porque tenemos muchas batallas que luchar`,
        `Debemos ver a Luminiar y al Rey Davinar resurgir una vez más.`,
        `el 25 de mayo de 2024 en Schroon Lake`
      ]
    :
      [
        `Excellent, ${user.real_first_name}!`,
        `Did you know it was me the whole time?`,
        `This little Dave-Bug got ya!`,
        `Well, welcome to the Eclipsed Vanguard -- The offical title of my Groomsman!`,
        `Must'nt you forget, your role as the ${user.vanguardian_title}.`,
        `In a moment, your role duties will appear.  Abide by them, old Vanguardian.`,
        `The Umbral Dominion needs not sleep nor rest.  So we must NOT slow down.`,
        `For we have many battles to fight`,
        `We must see Luminiar and King Davinar rise once again.`,
        `on May 25th, 2024 in Schroon Lake`
      ];
  return messageSetTwo;
};