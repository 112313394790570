import { useUser } from '../../contexts/userContext';
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom'
import Cookies from 'js-cookie'; 
import useOpenAi from '../utils/hooks/useOpenAi'
import { parseResponse } from '../utils/helpers/parsers';
import { createGPTInstructions, getRandomLoadingMessage } from '../utils/data'

const Riddle = () =>{
  const { createThreadAndRun, getMessagesAfterRun, getRunStatus, createNewAssistant, createNewMessageOnThread, createRunOnThread, error } = useOpenAi()
  const navigate = useNavigate();
  const { currentUser, language } = useUser();
  const [threadId, setThreadId] = useState('')
  const [runQueued, setRunQueued] = useState(true)
  const [runStatusComplete, setRunStatusComplete] = useState(false)
  const [runId, setRunId] = useState(null)
  const [messageHistory, setMessageHistory] = useState([])
  const [assistantId, setAssistantId] = useState(Cookies.get('assistant_id'))
  const [userMessage, setUserMessage] = useState('')
  const [assistantTextResponse, setAssistantTextResponse] = useState('')
  const [solvedFirstRiddle, setSolvedFirstRiddle] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const [runThreadCheckCount, setRunThreadCheckCount] = useState(0)
  const [userSubmitted, setUserSubmitted ] = useState(false)

  // set Assistant
  useEffect(() => {
    const assistantIdFromCookies = Cookies.get('assistant_id')
    if(error){
      console.log('error contacting GPT', error)
      createNewGptAssistant();
    }
    if(!assistantIdFromCookies){
      createNewGptAssistant();
    } 
    
    if(!assistantId || assistantId !== assistantIdFromCookies){
      setAssistantId(assistantIdFromCookies)
    }
    // Auto-trigger the greeting message
    if (assistantId && !threadId) {
      createGreetingMessage();
    }
  }, [assistantId, error])

  // initializers
  const createNewGptAssistant = async () => {
    const instructions = createGPTInstructions(currentUser)
    const newAssistantResp = await createNewAssistant(instructions, currentUser.short_name, "gpt-3.5-turbo-1106")
    if(newAssistantResp) {
      Cookies.set('assistant_id', newAssistantResp.data.id)
      setAssistantId(newAssistantResp.data.id)
    }
  }

  useEffect(() => {
    if(runQueued === true){
      setIsLoading(true)
    } else {
      setIsLoading(false)
    }
  }, [isLoading, runQueued])
  
  const createGreetingMessage = async () => {
    const firstName = currentUser.real_first_name
    const prompt = `${firstName} has arrived and he is ready to begin his journey but he needs to be briefed on his first riddle.   Please greet him, and let him know who you are, and that you may be used for help and hints, then ask if he is ready for his first riddle.  NOTE: please follow your instructions, and return only a json like object.`
    try {
      if(!assistantId){
        console.log('NO ASSISTANT ID')
        return
      }
      const thread = await createThreadAndRun(prompt, assistantId)

      if(thread) {
        const threadIdFromResp = thread.data.thread_id
        const runIdFromResp = thread.data.id

        setThreadId(threadIdFromResp)
        setRunId(runIdFromResp)
        setRunQueued(true)
        setRunStatusComplete(false)
        
      }
    } catch (error) {
      
    }
  }

// Component Mounting -- Setting Thread ID
  useEffect(()=> {
    if(threadId){
      if(Cookies.get('threadId') !== threadId) {
        Cookies.set('threadId', threadId)
      }
    }

  }, [threadId])

  // checking run status 
  useEffect(() => {
    let intervalId;

    const fetchRunStatus = async () => {
      if (runThreadCheckCount > 12) {
        clearInterval(intervalId); // Stop polling after 12 attempts
        // need to handle this better
        return; // Exit the function
      }

      if (threadId && runId && runQueued) {
        setRunThreadCheckCount(runThreadCheckCount + 1)
        console.log('runThreadCheckCount', runThreadCheckCount)
        const run = await getRunStatus(threadId, runId);
        console.log('run.data.status', run.data.status)
        if(run) {
          const runStatus = run.data.status;
          if (runStatus === 'failed') {
            resetStateVariables()
            resetGpt()
          }
          if (runStatus === 'completed') {
            console.log('RUN STATUS COMPLETED 🎉🎉🎉')
            setRunThreadCheckCount(0)
            setRunStatusComplete(true);
            setRunId(null);
            clearInterval(intervalId); // Clear interval when completed
          }
        }
      }
    };
  
    if (threadId && runId && runQueued) {
      intervalId = setInterval(fetchRunStatus, 5000); // Poll every 5 seconds
    }
  
    return () => {
      clearInterval(intervalId); // Clear interval when component unmounts
    };
  }, [threadId, runId, runQueued, runThreadCheckCount]);

  // Fetch messages
  useEffect(() => {
    const fetchMessages = async () => {
      if(runStatusComplete && runQueued) {
        const getMessages = await getMessagesAfterRun(threadId)
        if(getMessages) {
          setUserSubmitted(false)
          setRunQueued(false)
          const data = getMessages.data.data[0]
          if(data.role === 'assistant'){
            const messageContent = data.content[0].text.value
            const { parsedContent, error } = parseResponse(messageContent)
            if (error) {
              resetStateVariables();
              resetGpt();
            } else {
              setUserSubmitted(false)
              // console.log('parsedContent.gpt_response', parsedContent.gpt_response)
              setAssistantTextResponse(parsedContent.gpt_response)
              setSolvedFirstRiddle(parsedContent.correct_answer === true)
              setMessageHistory(prevHistory => [...prevHistory, { sender: "gpt-bot", message: parsedContent.gpt_response }]);
            }
          }
        }
      }
    };
  
    fetchMessages();
  }, [runQueued, threadId, runStatusComplete]);

  const handleUserSubmit = async(e) => {
    e.preventDefault()
    setUserSubmitted(true)
    const prompt = `Message from ${currentUser.real_first_name}: ${userMessage}.  NOTE: please follow your instructions, and return only a json like object.`
    const newMessage = await createNewMessageOnThread(prompt, threadId)
    if(newMessage){
      setMessageHistory(prevHistory => [...prevHistory, { sender: "vanguardian", message: userMessage }]);
      const newRun = await createRunOnThread(threadId, assistantId)
      if(newRun){
        setRunId(newRun.data.id)
        setRunQueued(true)
        setRunStatusComplete(false)
        setUserMessage('')
      }
    }
  }
  // Reset state variables in case error
  const resetStateVariables = (e) => {
    if(e){
      e.preventDefault()
    }
    setRunThreadCheckCount(0)
    setThreadId(null)
    setRunQueued(false)
    setRunStatusComplete(false)
    setRunId(null)
    setAssistantTextResponse('')
    setIsLoading(true)
    setSolvedFirstRiddle(false)
    setMessageHistory('')
    setUserSubmitted(false)
  }

  // Reset assistant id-- should generate a new one
  const resetGpt = (e) => {
    if(e){
      e.preventDefault()
    }
    setAssistantId(null)
    Cookies.remove('assistant_id')
    console.log('assistantId', assistantId)
    console.log('cookies', assistantId)
  }

  const LoadingSpinner = () => {
    return (
      <div className="flex justify-center items-center text-sm my-auto">
        <svg className="animate-pulse h-5 w-5 text-grey-500" viewBox="0 0 24 24">
          <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
          <path className="opacity-75" fill="currentColor" d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2z"></path>
        </svg>
         {waitMessage()}
      </div>
    );
  };

  const waitMessage = () => {
    if(runThreadCheckCount < 9) {
      return getRandomLoadingMessage(currentUser)
    }else if(runThreadCheckCount > 9 && runThreadCheckCount < 13){
      return "don't even THINK about refreshing"
    } else if(runThreadCheckCount >= 13){
      return "Oop. Got a brainfart.. go ahead and refresh now. Sorry."
    }
  }

  const handleMoveToNextStep = (e) => {
    e.preventDefault();

    const audioElement = document.querySelector('audio');
    const currentTime = audioElement.currentTime;

    navigate('/vanguardian/obtain-your-task', { state: { messageHistory }, audioTime: currentTime});
  };
  
  return (
    <div className="flex flex-col bg-black h-screen justify-center">
      <audio src='https://eclipsed-vanguard.s3.amazonaws.com/audio/ES_Mysterious+Forest+-+Howard+Harper-Barnes.mp3' style={{ display: 'none' }} autoPlay loop>
        Your browser does not support the audio element.
      </audio>
      {userSubmitted && <audio id="submit-sound" src='https://eclipsed-vanguard.s3.amazonaws.com/audio/ES_Devil+Laugh+2+-+SFX+Producer.mp3' style={{ display: 'none' }} autoPlay>
        Your browser does not support the audio element.
      </audio>}

      <div className="text-3xl mt-12 text-left mr-6 ml-6 text-3xl font-bold text-gray-700 dark:text-gray-300">
        {isLoading && <LoadingSpinner/>}
        {!isLoading && <h4 className={`transition-opacity duration-700 fade-in`}>
          {assistantTextResponse}
        </h4>}
      </div>

      {!isLoading && !error && <form className="mt-10 flex flex-col" onSubmit={handleUserSubmit}>
        <div className="justify-center flex mt-10">
          <input 
            onChange={e => setUserMessage(e.target.value)}
            value={userMessage}
            type="text" 
            name="user input"
            id="input" 
            placeholder={language === 'Spanish' ? 'Pregúntame o responde con cualquier cosa.' : 'Ask me or respond with anything.'}
            className="w-10/12 h-16 bg-gray-50 border text-md border-gray-300 text-gray-900 sm:text-md rounded-lg focus:ring-primary-600 focus:border-primary-600 block p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" 
            required={true}
          />
        </div>
        <button 
          type="submit" 
          className={`${solvedFirstRiddle ? '' : 'animate-pulse'} text-white mx-auto mt-4 border-2 text-sm w-3/4 bg-primary-600 hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-5xl px-6 py-3.5 text-center dark:bg-primary-800 dark:hover:bg-primary-700 dark:focus:ring-primary-800 mt-2`}
          >
            {solvedFirstRiddle ? `${language === 'Spanish' ? 'Pregúntame más' : 'Ask me more'}` : `${language === 'Spanish' ? 'Pregúntale al guardián de los grandes secretos.': 'Ask the Keeper of Great Secrets'}`}
        </button>
      </form>}

      {solvedFirstRiddle &&
         <button 
         type="submit"
         onClick={handleMoveToNextStep}
         className="animate-pulse mx-auto text-sm w-10/12 border-2 text-white bg-primary-600 hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-5xl px-6 py-3.5 text-center dark:bg-primary-800 dark:hover:bg-primary-700 dark:focus:ring-primary-800 mt-2"
         >
           {language === 'Spanish' ? '>> Obtener Su Tarea >>' : '>> Obtain Your Task >>'}
       </button>}

    </div>
  )
}

export default Riddle;



{/* <button
  className='text-xl w-full text-white bg-primary-600 hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-5xl px-6 py-3.5 text-center dark:bg-primary-800 dark:hover:bg-primary-700 dark:focus:ring-primary-800 mt-2'
  onClick={resetStateVariables}
> 
    <h1>resetStateVariables</h1>
</button>
<button
  className='text-xl w-full text-white bg-primary-600 hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-5xl px-6 py-3.5 text-center dark:bg-primary-800 dark:hover:bg-primary-700 dark:focus:ring-primary-800 mt-2'
  onClick={resetGpt}
> 
    <h1>resetGpt</h1>
</button> */}
