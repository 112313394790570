import React from 'react';
import { Navigate } from 'react-router-dom';
import { useUser } from '../contexts/userContext';

const ProtectedRoute = ({ element }) => {
  const { currentUser } = useUser();

  return currentUser ? element : <Navigate to="/login" />;
};

export default ProtectedRoute;