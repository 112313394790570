import { useEffect, useState, useRef } from "react";
import { useUser } from '../../contexts/userContext';
import { finalMessageSetOne, finalMessageSetTwo } from "../utils/data";

const FinalTask = () => {
  const [currentMessageIndex, setCurrentMessageIndex] = useState(0)
  const [messageToRender, setMessageToRender] = useState('')
  const [instructionsRendered, setInstructionsRendered] = useState(false)
  const [fadeIn, setFadeIn ] = useState(false)
  const { currentUser, language } = useUser()
  const [inputValues, setInputValues] = useState({ digit1: '', digit2: '', digit3: '' });
  const digit2Ref = useRef();
  const digit3Ref = useRef();
  const [isButtonActive, setIsButtonActive] = useState(false)
  const [displayEndingMessage, setDisplayEndingMessage] = useState(false)
  const [audioLink, setAudioLink] = useState('https://eclipsed-vanguard.s3.amazonaws.com/audio/ES_We+Are+Heroes+-+Bonnie+Grace.mp3')
  const [renderRoleDiscription, setRenderRoleDescription] = useState(false)
  
  const currentTime = new Date();
  const detonationTime = new Date(currentTime.getTime() + 60*60*1000); // Adds one hour
  const formattedDetonationTime = detonationTime.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }); // Formats the time

  const audioLinks = [
    'https://eclipsed-vanguard.s3.amazonaws.com/audio/ES_We+Are+Heroes+-+Bonnie+Grace.mp3',
    'https://eclipsed-vanguard.s3.amazonaws.com/audio/ES_Valley+of+the+Kings+-+Hampus+Naeselius.mp3',
  ]

  
  useEffect(()=>{
    const messages = instructionsRendered && displayEndingMessage ? finalMessageSetTwo(currentUser) : finalMessageSetOne(currentUser, formattedDetonationTime)
    const intervalTime = instructionsRendered && displayEndingMessage ? 8500 : 9850
    const fadeInOffset = instructionsRendered && displayEndingMessage ? "4250" : "5250"
    
    // console.log('messages.length', messages.length)
    // console.log('currentMessageIndex', currentMessageIndex)
    if (!displayEndingMessage && currentMessageIndex > messages?.length) {
      setInstructionsRendered(true)
      return;
    } else if(instructionsRendered && displayEndingMessage && currentMessageIndex > messages?.length){
      setFadeIn(true)
      setMessageToRender(currentUser.role_description)
      return;
    }

    const intervalId = setInterval(() => {
      setMessageToRender(messages[currentMessageIndex])
      setFadeIn(true)
      setCurrentMessageIndex(currentMessageIndex + 1)

      setTimeout(() => {
        if(!displayEndingMessage){
          setFadeIn(false)
        }
      }, fadeInOffset);
    }, intervalTime); // Base message changes on crescendo of music (10675)
    return () => clearInterval(intervalId);
  }, [currentMessageIndex, displayEndingMessage, instructionsRendered])

  useEffect(()=>{
    if(inputValues?.digit1 && inputValues?.digit2 && inputValues?.digit3){
      setIsButtonActive(true)
    }

  },[inputValues])

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setInputValues({ ...inputValues, [name]: value });

    // Auto-focus next input when a digit is entered
    if (value && name === 'digit1') {
      digit2Ref.current.focus();
    }
    if (value && name === 'digit2') {
      digit3Ref.current.focus();
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault()
    const enteredCode = `${inputValues.digit1}${inputValues.digit2}${inputValues.digit3}`
    if(enteredCode === '357'){
      setCurrentMessageIndex(0)
      setDisplayEndingMessage(true)
      setAudioLink(audioLinks[1])
    }else{
      setIsButtonActive(false)
      setInputValues({ digit1: '', digit2: '', digit3: '' })
    }
  }

  return (
    <div className="flex flex-col bg-black h-screen justify-center">
      <audio src={audioLink} style={{ display: 'none' }} autoPlay>
        Your browser does not support the audio element.
      </audio>
      <div className="flex flex-col justify-center mb-14 text-xl mt-12 text-center text-3xl font-bold text-gray-700 dark:text-gray-300 mt-14 mx-4"
          style={{ height: '1em' }}>
          <h4 className={`transition-opacity duration-3000 ${fadeIn ? '.visible' : 'fade-out'}`}>
            {messageToRender}
          </h4>
      </div>
    
     {instructionsRendered && !displayEndingMessage && <div className="mb-14 text-xl mt-12 text-center text-3xl font-bold text-gray-700 dark:text-gray-300 mt-14">
        <h4  className={`transition-opacity duration-3000`}>
            {`${language === 'Spanish' ? 'Ubicación' : 'Location'}: ${currentUser.riddle_location_name}`}
        </h4>
        <h4  className={`transition-opacity duration-3000`}>
            {`${language === 'Spanish' ? 'Bebida a Pedido' : 'Item to Order'}: ${currentUser.drink_name}`}
        </h4>

        <form onSubmit={handleSubmit} className="w-full mt-12">
          <div className="flex flex-row justify-center">
            <input
              onChange={handleInputChange}
              value={inputValues.digit1}
              type="tel"
              name="digit1"
              className="mx-width-50px text-center text-black text-xl bg-gray-200 border border-gray-300 rounded-md p-2 mx-2"
              maxLength={1}
              required={true}
              autoFocus
          />
            <input
              ref={digit2Ref}
              onChange={handleInputChange}
              value={inputValues.digit2}
              type="tel"
              name="digit2"
              className="mx-width-50px text-center text-black text-xl bg-gray-200 border border-gray-300 rounded-md p-2 mx-2"
              maxLength={1}
              required={true}
            />
            <input
              ref={digit3Ref}
              onChange={handleInputChange}
              value={inputValues.digit3}
              type="tel"
              name="digit3"
              className="mx-width-50px text-center text-black text-xl bg-gray-200 border border-gray-300 rounded-md p-2 mx-2"
              maxLength={1}
              required={true}
            />
            {/* <input 
              onChange={handleInputChange}
              value={inputValue}
              type="number" 
              name="digit 1"
              id="input" 
              placeholder="" 
              className="max-w-sm h-16 bg-gray-50 border text-md border-gray-300 text-gray-900 sm:text-md rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" 
              required={true}
            /> */}
           </div>
            <button
              type="submit"
              disabled={!isButtonActive}
              className={`border-2 text-sm w-3/4 text-white bg-primary-600 font-medium rounded-lg text-5xl px-6 py-3.5 text-center dark:bg-primary-800 mt-2 ${!isButtonActive ? 'opacity-50 cursor-not-allowed' : 'animate-pulse hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 dark:hover:bg-primary-700 dark:focus:ring-primary-800'}`}
              >
                {language === 'Spanish' ? 'Desbloquear El Eestino' : 'Unlock Destiny'}
            </button>
        </form>
      </div>}
    </div>
  )
}

export default FinalTask;