import { useState } from 'react';
import axios from 'axios';

const useOpenAI = () => {
  const [response, setResponse] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const headers = {
    'Authorization': `Bearer ${process.env.REACT_APP_OPENAI_API_KEY}`,
    'OpenAI-Beta': 'assistants=v1',
    'Content-Type': 'application/json'
  }

  const createNewAssistant = async (instructions, vangaurdUser, gptModel) => {
    setError(null)
    setIsLoading(true)
    if(!prompt){
      throw new error
    }

    const currentDate = new Date().toLocaleDateString();
    const body = JSON.stringify({
      "model": gptModel,
      "name": `Keeper of Great Secrets-TURBO-${vangaurdUser}-${currentDate}`,
      "instructions": instructions
    })
    try {
      const result = await axios.post(
        `https://api.openai.com/v1/assistants`,
          body,
          { headers: headers }
      );

      // console.log('createNewAssistant', result)
      return result
    } catch (error) {
      console.error('Error communicating with OpenAI:', error);
      setError(error);
    } finally {
      setIsLoading(false);
    }
  }
  
  const createNewMessageOnThread = async (prompt, threadId) => {
    setIsLoading(true);
    setError(null);

    if(!threadId){
      throw new error
    }
    // console.log('prompt from openai', prompt)
    const body = JSON.stringify({
      "role": "user",
      "content": prompt
    })
    try {
      const response = await axios.post(
        `https://api.openai.com/v1/threads/${threadId}/messages`,
        body,
        { headers: headers }
      );

      // console.log('createNewMessageOnThread', response)
      return response
    } catch (error) {
      console.error('Error communicating with OpenAI:', error);
      setError(error);
    } finally {
      setIsLoading(false);
    }
  };

  const createRunOnThread = async (threadId, assistantId) => {
    setIsLoading(true);
    setError(null);

    if(!threadId){
      throw new error
    }

    const body = JSON.stringify({
      "assistant_id": assistantId
    })

    try {
      const response = await axios.post(
        `https://api.openai.com/v1/threads/${threadId}/runs`,
        body,
        { headers: headers}
      );

      // console.log('createRunOnThread', response)
      return response
    } catch (error) {
      console.error('Error communicating with OpenAI:', error);
      setError(error);
    } finally {
      setIsLoading(false);
    }
  }

  const getRunStatus = async (threadId, runId) => {
    setIsLoading(true);
    setError(null);
    
    if(!threadId || !runId) {
      setError(`Something is null ==> threadId: ${threadId} || runId: ${runId}`)
    }
    try {
      const response = await axios.get(
        `https://api.openai.com/v1/threads/${threadId}/runs/${runId}`,
        {
          headers: headers
        }
      );

      // console.log('runStatus', response)
      return response
    } catch (error) {
      console.error('Error communicating with OpenAI:', error);
      setError(error);
    } finally {
      setIsLoading(false);
    }
  }


  const createThreadAndRun = async (prompt, assistantId) => {
    setError(null)
    setIsLoading(true)
    if(!prompt){
      setError('No Prompt loaded on createThreadAndRun')
      setIsLoading(false)
    }



    const body = JSON.stringify({
      "assistant_id": assistantId,
      "thread": {
        "messages": [
          {
            "role": "user",
            "content": prompt
          }
        ]
      }
    })

    try {
      const result = await axios.post(
        `https://api.openai.com/v1/threads/runs`,
          body,
          { headers: headers }
      );

      // console.log('createThreadandRun', result)
      return result
    } catch (error) {
      console.error('Error communicating with OpenAI:', error);
      setError(error);
    } finally {
      setIsLoading(false);
    }

  }

  const getMessagesAfterRun = async (threadId, msgLimit=null) => {
    setError(null)
    setIsLoading(true)
    
    if(!threadId){
      setError('Error: no threadId.  ThreadId must be passed')
    }

    const limit = msgLimit ? msgLimit : 1;

    try {
      const result = await axios.get(
        `https://api.openai.com/v1/threads/${threadId}/messages?limit=${limit}`,
        {
          headers: headers
        }
      );

      // console.log('GetMessagesAfterRun', result)
      return result

    } catch (error) {
      console.error('Error communicating with OpenAI:', error);
      setError(error);
    } finally {
      setIsLoading(false);
    }

  }

  return { response, getMessagesAfterRun, createNewMessageOnThread, createRunOnThread, createThreadAndRun, getRunStatus, createNewAssistant, isLoading, setIsLoading, error };
};

export default useOpenAI;
