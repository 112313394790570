import { useState, useEffect } from 'react'
import { dynamodb } from './utils/config/awsConfig'
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom'
import { useUser } from '../contexts/userContext';

import axios from 'axios'
import { jwtDecode } from 'jwt-decode'

// URL TO GO TO FOR TESTING: http://localhost:3000/?dmFuZ3VhcmRpYW4%3D=aG9ub3Jfd2FyZGVu

// eternal_bonds: http://localhost:3000/?dmFuZ3VhcmRpYW4%3D=ZXRlcm5hbF9ib25kcw==
// sacred_oaths: http://localhost:3000/?dmFuZ3VhcmRpYW4%3D=c2FjcmVkX29hdGhz
// bean_sultan: http://localhost:3000/?dmFuZ3VhcmRpYW4%3D=YmVhbl9zdWx0YW4=
// enchanted_embers: http://localhost:3000/?dmFuZ3VhcmRpYW4%3D=ZW5jaGFudGVkX2VtYmVycw==
// unifying_spirits: http://localhost:3000/?dmFuZ3VhcmRpYW4%3D=dW5pZnlpbmdfc3Bpcml0cw==
// brotherhood_quest: http://localhost:3000/?dmFuZ3VhcmRpYW4%3D=YnJvdGhlcmhvb2RfcXVlc3Q=
// honor_warden: http://localhost:3000/?dmFuZ3VhcmRpYW4%3D=aG9ub3Jfd2FyZGVu
// siblinghood_sustainer: http://localhost:3000/?dmFuZ3VhcmRpYW4%3D=c2libGluZ2hvb2Rfc3VzdGFpbmVy
// kinship_flame: http://localhost:3000/?dmFuZ3VhcmRpYW4%3D=a2luc2hpcF9mbGFtZQ==
// nautical_nexus: http://localhost:3000/?dmFuZ3VhcmRpYW4%3D=bmF1dGljYWxfbmV4dXM=

// eternal_bonds
// sacred_oaths
// bean_sultan
// enchanted_embers
// unifying_spirits
// brotherhood_quest
// honor_warden
// siblinghood_sustainer
// kinship_flame
// nautical_nexus

// Passcodes

// Colby TimelessWarden
// Brian	PledgeKeeper
// Mike	BeansForDayz
// Allan FlameSentinel
// Joe	EchoWhisper
// Zach	ValorBond
// Tony	GuardEcho
// Matt	EchoBond
// Nick	LegacyLink
// Adam	NauticalSage

const Login = (props) => {
  // State Variables
  const [passcode, setPasscode] = useState('')

  const navigate = useNavigate();
  const { setCurrentUser } = useUser();

  useEffect(()=> {
    // console.log('this is the error from UE', props.error)
  },[props.error])

  const handleSubmit = async (e) =>{
    e.preventDefault()
    try {
      props.setLoadingState(true)
      axios.post(process.env.REACT_APP_AWS_CUSTOM_API_URL, {
        user_id: props.userId,
        passcode: passcode
      }, {
        headers: {
        'Content-Type': 'application/json',
        'X-Api-Key': process.env.REACT_APP_AWS_CUSTOM_API_GATEWAY_KEY
        }
      })
      .then(response => {
        if(response.data.statusCode === 401){
          props.setLoadingState(false)
          props.setIntroTextRendered(true)
          props.setError(props.language === 'Spanish' ? 'Secreto equivocado. Intentar otra vez.' : 'Wrong secret. Try again.')
        } else if(response.status == 200 && response.data.body){
          const responseBody = JSON.parse(response.data.body)
          const token = responseBody?.token
          if(token) {
            const userPayload = jwtDecode(token);
            setCurrentUser(userPayload)
            Cookies.set('jwtToken', token, { expires: 1 });
            localStorage.setItem('vanguardianUser', JSON.stringify(userPayload));
            props.setLoadingState(false)
            navigate('vanguardian/start-the-journey')
          }
        } else {
          console.log('something else happened', response)
          props.setLoadingState(false)
          props.setError(props.language === 'Spanish' ? 'Se produjo un error inesperado, inténtalo de nuevo.' : 'An unexpected error occured, please try again.')
        }
      })
      
    } catch (error) {
      props.setError(props.language === 'Spanish' ? 'Se produjo un error inesperado, inténtalo de nuevo.' : 'An unexpected error occured, please try again.')
      props.setLoadingState(false)
      props.setIntroTextRendered(true)
    }
  }


  ////////////////////////////////////////////
  // User input  
  const handleInputChange = (e) => {
    e.preventDefault()
    props.setError(false)
    setPasscode(e.target.value);
  }

  return (
    <div>
      <div className="mt-12 text-center font-bold text-white">
        <h4 className="mb-6 text-xl mt-12 text-center font-bold text-white">
          {props.vanguardianTitle}
        </h4>
      </div>
      <img className="mb-8" id="vanguardLogo" src={props.crestUrl} alt="img-icon"/>
      <form className="mt-10" onSubmit={handleSubmit}>
        <div className="justify-center flex flex-col items-center mt-10">
          {props.error && <p className="text-sm text-red-600 mb-4">{props.error}</p>}
          <input 
            onChange={handleInputChange}
            value={passcode}
            type="password" 
            name="password"
            id="password" 
            placeholder={props.language === 'Spanish' ? "Entrada Secreto Vanguardian" : "Input Vanguardian Secret"} 
            className="max-w-sm h-16 bg-gray-50 border text-sm border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 text-center" 
            required={true}
            />
        </div>
        <button
          type="submit" 
          className="animate-pulse text-6xl w-full text-white bg-primary-600 hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-5xl px-6 py-3.5 text-center dark:bg-primary-800 dark:hover:bg-primary-700 dark:focus:ring-primary-800 mt-2"
          >
            {props.language === 'Spanish' ? "Entrar" : "Enter"}
        </button>
      </form>
    </div>
  )   
}

export default Login;