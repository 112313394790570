import { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { dynamodb } from './utils/config/awsConfig'
import Login from './UserLogin';
import { openingMessages } from './utils/data'

const Home = () =>{
  const [queryParamsPresent, setQueryParamsPresent] = useState(false)
  const location = useLocation();
  const encodedParam = btoa('vanguardian')
  const [preliminaryUserData, setPreliminaryUserData] = useState({})
  const [shortName, setShortName] = useState(''); // comes from param
  const [formSubmitted, setFormSubmitted] = useState(false)
  const [inputValue, setInputValue] = useState('')
  const [isLoading, setIsLoading] =  useState(false)
  const [introTextRendered, setIntroTextRendered] = useState(false)
  const [greetingMessageRendered, setGreetingMessageRendered] = useState(false)
  const [currentMessageIndex, setCurrentMessageIndex] = useState(0)
  const [initialTimeoutEnded, setInitialTimeoutEnded] = useState(false)
  const [messageToRender, setMessageToRender] = useState('')
  const [greetingMessages, setGreetingMessages] = useState(null)
  const [fadeIn, setFadeIn] = useState(false)
  const [error, setError] = useState(false)
  const [language, setLanguage] = useState(null)

   //////////////////////////////////////////
  // if vanguardian name is in params
  useEffect(() => {
    // console.log('currentMessageIndex', currentMessageIndex)
    // Parse the query parameters from the URL
    const searchParams = new URLSearchParams(location.search);
  
    // Check to see if query params were passed
    const searchParamKeys = searchParams.keys()
    for (const key of searchParamKeys){
      const decodedKey = atob(key)
      if(decodedKey === 'vanguardian'){
        setQueryParamsPresent(true)
      } else {
        setQueryParamsPresent(false)
      }
    }

    // Get the value of the 'vanguardian' parameter
    const queryParamValue = searchParams.get(encodedParam);
    const decodedString = atob(queryParamValue);
    setShortName(decodedString)
    // return user from Database from name
  }, [location.search, queryParamsPresent]);

  ////////////////////////////////////////// 
  // Handling submit of vanguardian short name
  const handleSubmit = async(e) => {
    e.preventDefault()
    setIsLoading(true)

    setTimeout(() => {
      setInitialTimeoutEnded(true)
    }, "1000");


    const params = {
      TableName: 'users',
      IndexName: 'short_name-index-alt2',
      KeyConditionExpression: 'short_name = :name',
      ExpressionAttributeValues: {
        ':name': inputValue.toLowerCase()
      }
    }
    if(!dynamodb) return

    try {
      dynamodb.query(params, (error, data) => {
        if (error){
          console.log('error from dynamodb', error)
          setError('An error occurred. Please try again.');
        } else {
          if(data.Items && data.Items.length > 0){
            console.log(`Ahhh, you're a smart one I see. Nice try checking here to see what this is, ${data.Items[0].real_first_name}.  This won't work.  Continue to your destiny.`)
            setPreliminaryUserData(data.Items)
            setFormSubmitted(true)
            setIsLoading(false)
            setCurrentMessageIndex(0)
            setIntroTextRendered(false)
            if(data.Items[0].real_first_name === 'Allan'){
              setLanguage('Spanish')
            }else{
              setLanguage('English')
            }
          } else {
            setError('Invalid Vanguardian nickname, please try again.');
            setIsLoading(false)
            setInputValue('')
          }
        }
      })
    } catch (error) {
      console.error('error contacting dynamodb, error:', error)
      setError('An error occurred. Please try again.');
    }


  }
   //////////////////////////////////////////
  // loading spinner
  const LoadingSpinner = () => {
    return (
      <div className="flex justify-center items-center text-sm" id="look-for-this">
        <svg className="animate-pulse h-5 w-5 text-grey-500" viewBox="0 0 24 24">
          <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
          <path className="opacity-75" fill="currentColor" d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2z"></path>
        </svg>

        {language === 'Spanish' ? '...la esperanza es el destino' : '...hope is destiny'}
      </div>
    );
  };

  const firstName = preliminaryUserData[0]?.real_first_name
  const lastName = preliminaryUserData[0]?.real_last_name
  const vanguardianTitle = preliminaryUserData[0]?.vanguardian_title
  const userId = preliminaryUserData[0]?.user_id
  const crestUrl = preliminaryUserData[0]?.crest_url

  //////////////////////////////////////////
  // setting greeting messages based on language/user
  useEffect(() => {
    if(formSubmitted && preliminaryUserData && language && !greetingMessages){
      // console.log('INSIDE THE CONDITIONAL')
      setGreetingMessages(openingMessages(language))
      // console.log('greetingMessages set', greetingMessages)
    }
  }, [greetingMessages, formSubmitted, preliminaryUserData, language]);
 

   //////////////////////////////////////////
  // Handling cascading messages
  useEffect(() => {
    // Stop the interval when all messages are displayed
    if (currentMessageIndex > greetingMessages?.length) {
      setIntroTextRendered(true)
      return;
    }

    let intervalId;
    if(formSubmitted && greetingMessages){
      if(!greetingMessageRendered) {
        setTimeout(()=>{
          setMessageToRender(`${language === 'Spanish' ? 'Salve' : 'Greetings'}, ${firstName}`)
          setFadeIn(true)
          setTimeout(() => {
            setFadeIn(false)
          }, "4000");
          setGreetingMessageRendered(true)
        }, "2000")
      }

      intervalId = setInterval(() => {
        // console.log('greetingMessages from setInterval', greetingMessages)
        setMessageToRender(greetingMessages[currentMessageIndex])
        setFadeIn(true)
        setCurrentMessageIndex(currentMessageIndex + 1)
        
        setTimeout(() => {
          setFadeIn(false)
        }, "6250");
      }, 10675); // Base message changes on crescendo of music (10675)
    }
    return () => clearInterval(intervalId); // Clean up the interval
  }, [currentMessageIndex, formSubmitted, greetingMessages]);

  ////////////////////////////////////////////
  // User input  
  const handleInputChange = (e) => {
    e.preventDefault()
    setInputValue(e.target.value);
    // Reset the error state when the user starts typing again
    setError('');
  }


  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  return (
    <div className="bg-black flex justify-center items-center h-screen overflow-auto">
      <div className="w-full max-w-md mx-auto p-4">
        {!formSubmitted &&
          <div className="flex flex-col items-center">
            <img className="mb-8" id="vanguardLogo" src="https://eclipsed-vanguard.s3.amazonaws.com/images/vanguard-black-background.png" alt="img-icon"/>
            <form onSubmit={handleSubmit} className="w-full">
              <div className="flex flex-col items-center">
                {error && <p className="text-sm text-red-600 mb-4">{error}</p>}
                <input 
                  onChange={handleInputChange}
                  value={inputValue}
                  type="text" 
                  name="user input"
                  id="input" 
                  placeholder="Enter Vanguardian Nick Name" 
                  className="max-w-sm h-16 bg-gray-50 border text-md border-gray-300 text-gray-900 sm:text-md rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" 
                  required={true}
                />
                <button 
                  type="submit" 
                  className="animate-pulse border-2 text-sm w-full text-white bg-primary-600 hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-5xl px-6 py-3.5 text-center dark:bg-primary-800 dark:hover:bg-primary-700 dark:focus:ring-primary-800 mt-2"
                  >
                    Request Entry
                </button>
              </div>
            </form>
          </div>}
      {formSubmitted && isLoading && <div className="text-3xl mt-12 text-left ml-6 text-3xl font-bold text-gray-700 dark:text-gray-300"><LoadingSpinner/></div>}
          {formSubmitted && !isLoading &&
            <audio src='https://eclipsed-vanguard.s3.amazonaws.com/audio/ES_Forging+an+Empire+-+Christoffer+Moe+Ditlevsen.mp3' style={{ display: 'none' }} autoPlay>
              Your browser does not support the audio element.
            </audio>
          }
        {formSubmitted && !isLoading && initialTimeoutEnded &&
          <div className="space-y-4 md:space-y-6 sm:p-8 p-7 mt-6">
            <div className={`text-center text-4xl font-bold leading-tight tracking-tight text-gray-900 dark:text-white`}>
              <div className="mb-14 text-xl mt-12 text-center text-3xl font-bold text-gray-700 dark:text-gray-300 mt-14"
                style={{ height: '1em' }}>
                <h4 className={`transition-opacity duration-3000 ${fadeIn ? '.visible' : 'fade-out'}`}>
                  {messageToRender}
                </h4>
              </div>
              {introTextRendered &&
              (<div className="mt-9">
                <Login 
                  shortName={shortName}
                  firstName={firstName}
                  lastName={lastName}
                  vanguardianTitle={vanguardianTitle}
                  crestUrl={crestUrl}
                  userId={userId}
                  setLoadingState={setIsLoading}
                  setError={setError}
                  error={error}
                  setIntroTextRendered={setIntroTextRendered}
                  language={language}
                />
              </div>)}
            </div>
          </div>
        }
      </div>
      </div>
  )
}

export default Home;