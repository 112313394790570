export const parseResponse = (messageContent) => {
  try {
    let cleanedContent = messageContent.trim();
    
    // Check for JSON block within triple backticks
    const jsonBlockPattern = /```json\s*({[\s\S]*?})\s*```/;
    const jsonBlockMatch = cleanedContent.match(jsonBlockPattern);

    if (jsonBlockMatch && jsonBlockMatch[1]) {
      // Found JSON block, parse it
      const parsedContent = JSON.parse(jsonBlockMatch[1]);
      return { parsedContent, error: null };
    } else {
      // Fallback to regular parsing if JSON block is not found
      cleanedContent = cleanedContent.replace(/`{3}.*?`{3}/gs, ''); // Remove Markdown code block
      cleanedContent = cleanedContent.replace(/[^{]*({.*})[^}]*$/, '$1'); // Extract JSON object
      const parsedContent = JSON.parse(cleanedContent);
      return { parsedContent, error: null };
    }
  } catch (error) {
    console.error('Error parsing JSON:', error);
    return { parsedContent: null, error };
  }
};