import React, { useEffect, createContext, useState, useContext } from 'react';

const UserContext = createContext(null);

export const useUser = () => useContext(UserContext);

export const UserProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(() => {
    const storedUser = localStorage.getItem('vanguardianUser');
    return storedUser ? JSON.parse(storedUser) : null;
  });

    // State to handle language preference
    const [language, setLanguage] = useState('English');

    // Update language state based on currentUser
    useEffect(() => {
      if (currentUser && currentUser.real_first_name === 'Allan') {
        setLanguage('Spanish');
      } else {
        setLanguage('English');
      }
    }, [currentUser]);

  return (
    <UserContext.Provider value={{ currentUser, setCurrentUser, language, setLanguage }}>
      {children}
    </UserContext.Provider>
  );
};
