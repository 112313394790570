
//DEPENDENCIES AND IMPORTS
import { useEffect } from 'react'
import { useLocation, useNavigate, BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import React from 'react';
import { UserProvider, useUser } from './contexts/userContext';

//NAVIGATION/AUTHENTICATION COMPONENTS
// import TopNavbar from './components/TopNavbar'

import IntroVideo from './components/steps/IntroVideo'
import Riddle from './components/steps/Riddle'
import FinalTask from './components/steps/FinalTask'
import Home from './components/Home'
import Login from './components/UserLogin'
import ProtectedRoute from './components/ProtectedRoute'
import SecondRiddle from './components/steps/SecondRiddle';


import './App.css';

function App() {
  const Main = () => {
    const { currentUser } = useUser();
    const navigate = useNavigate();
    const location = useLocation();
  
    useEffect(() => {
      localStorage.setItem('lastPath', location.pathname);
    }, [location]);
  
    useEffect(() => {
      const isLoggedIn = currentUser !== null;
    
      if (isLoggedIn) {
        const lastPath = localStorage.getItem('lastPath');
        if (lastPath && lastPath !== '/login') {
          navigate(lastPath);
        }
      } else {
        navigate('/');
      }
    }, [navigate, currentUser]);
  
    return (
      <Routes>
        <Route
          path="/login"
          element={<Login />}
        />
        <Route
          path="/"
          element={<Home />}
        />
        <Route 
          path="vanguardian/start-the-journey"
          element={<ProtectedRoute element={<IntroVideo />}/>}
          />
        <Route
          path="vanguardian/your-first-test"
          element={<ProtectedRoute element={<Riddle />}/>}
        />
        <Route
          path="vanguardian/obtain-your-task"
          element={<ProtectedRoute element={<SecondRiddle />}/>}
        />
        <Route
          path="vanguardian/claim-your-destiny"
          element={<ProtectedRoute element={<FinalTask />}/>}
        />
      </Routes>
    );
  };
  

  return (
    <UserProvider>
      <Router>
        <Main/>
      </Router>
    </UserProvider>
  )
}

export default App;
