import { useUser } from '../../contexts/userContext';
import React, { useState, useEffect } from 'react';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom'

const IntroVideo = (props) =>{

  const { currentUser, language } = useUser();
  // TODO:
  // Welcome message of sorts with user's name
  // Prompt to play intro video (video should be its own component)
  // Store video whether video was watched in cookies -- once video is complete, display button to continue
  // Display button renders Riddle component

  const videoRef = React.createRef();
  const [watchComplete, setWatchComplete] = useState(false);
  const [watchVideoButtonClicked, setwatchVideoButtonClicked] = useState(false);
  const [videoWatchCount, setVideoWatchCount] = useState(0)
  const [currentMessageIndex, setCurrentMessageIndex] = useState(0)
  const [introTextRendered, setIntroTextRendered] = useState(false)
  const [messageToRender, setMessageToRender] = useState('')

  const navigate = useNavigate();
  Cookies.set('videoWatchCount', 1)
  useEffect(() => {
    const savedTime = Cookies.get('videoTime');
    const videoWatched = Cookies.get('videoCompleted')
    if(videoWatched === true){
      setWatchComplete(true)
    }
    if (savedTime && videoRef.current) {
      videoRef.current.currentTime = parseFloat(savedTime);
    }
  }, [videoRef, watchComplete]);

  useEffect(()=> {
    const videoWatchCountCookies = Cookies.get('videoWatchCount')
    if(videoWatchCountCookies > 0) {
      setVideoWatchCount(1)
      Cookies.set('videoCompleted', true)
    }
  }, [videoWatchCount])

  const handleTimeUpdate = (e) => {
    // prevent controlled scrubbing if user already watched video
    if(videoWatchCount > 0){
      return
    }

    const currentTime = e.target.currentTime;
    const savedTime = parseFloat(Cookies.get('videoTime') || '0');
    if (currentTime > savedTime + 2) {
      videoRef.current.currentTime = savedTime;
    }
    // Save the current time
    Cookies.set('videoTime', currentTime, { expires: 7 });
  };

  const handleVideoEnd = () => {
    setWatchComplete(true);
    Cookies.set('videoCompleted', true)
    Cookies.set('videoWatchCount', 1)
    Cookies.remove('videoTime');
  };

  const handleContinueToRiddle = (e) => {
    e.preventDefault()
    navigate('/vanguardian/your-first-test')
  };

  const handleWatchVideoButton = (e) => {
    e.preventDefault()
    setwatchVideoButtonClicked(true)
  }

  const handleWatchVideoAgain = (e) => {
    e.preventDefault()

    setWatchComplete(false);
    setwatchVideoButtonClicked(true)
    Cookies.set('videoCompleted', false)
    Cookies.set('videoTime', 0.00)
  }

  const messages = [
    "Ah, brave soul, you've crossed the threshold into Luminiar, where legends intertwine with destiny.",
    `${currentUser.real_first_name}, recall the echoes of an age where you stood tall among the Eclipsed Vanguard.`,
    "In the shadowed corners of your memory lies the key to your forgotten saga.",
    "Listen closely, for the whispers of the past carry secrets of your once glorious path.",
    "Your journey through time and shadows begins now... dare to uncover the truth."
  ];

  const messagesInSpanish = [
    "Ah, valiente alma, has cruzado el umbral hacia Luminiar, donde las leyendas se entrelazan con el destino.",
    `${currentUser.real_first_name}, recuerda los ecos de una era donde te erguías alto entre la Vanguardia Eclipsada.`,
    "En los rincones sombríos de tu memoria yace la clave de tu saga olvidada.",
    "Escucha atentamente, pues los susurros del pasado llevan secretos de tu camino glorioso.",
    "Tu viaje a través del tiempo y las sombras comienza ahora... atrévete a descubrir la verdad."
  ];

  useEffect(() => {
    // Stop the interval when all messages are displayed
    if (currentMessageIndex >= messages.length) {
      setIntroTextRendered(true)
      return;
    }

    const intervalId = setInterval(() => {
      // console.log('currentMessageIndex', currentMessageIndex)
      setCurrentMessageIndex(currentIndex => currentIndex + 1);
    }, 5500); // Change message every 5.5 seconds

    return () => clearInterval(intervalId); // Clean up the interval
  }, [currentMessageIndex]);

  const messagesToRender = language === 'English' ? messages : messagesInSpanish
  const introMessage = () => {
    return (
      <div className="text-center font-bold text-white">
        <h4 className="mb-6 text-xl text-center font-bold text-white">
          {currentUser.vanguardian_title}
        </h4>
          {!introTextRendered &&
            <div className={`mb-14 text-xl mt-12 text-center font-bold text-white mt-14`}
              style={{ height: '1em' }}>
              <h1 className="transition-opacity duration-700 fade-in">
                {messageToRender}
              </h1>
            </div>
          }
          {introTextRendered &&
            <div className="bg-black flex flex-col items-center">
              <div className="flex justify-center width-100vw">
                <img className="mb-8 mx-width-80vw" id="vanguardLogo" src={currentUser.crest_url} alt="img-icon"/>
              </div>
              <button
                className="animate-pulse border-2 mt-12 text-sm w-3/4 text-white hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg py-3.5 text-center"
                onClick={handleWatchVideoButton}
                >
                Claim Your Destiny
              </button>
            </div>
          }
      </div>
    )
  }

  return (
    <div className="bg-black h-screen flex justify-center mx-height-100vh">
      <div className="text-3xl text-center text-3xl font-bold text-gray-700 dark:text-gray-300 my-auto">
        {/* Video player */}
        
        {!watchComplete &&
          <h1>{currentUser?.real_first_name}</h1> &&
          <video
            ref={videoRef}
            onTimeUpdate={handleTimeUpdate}
            onEnded={handleVideoEnd}
            controls
            autoPlay={true}
            className="my-auto"
            width="100%"
        >
          <source src="https://eclipsed-vanguard.s3.amazonaws.com/images/compressed-vanguard-video.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </video>}
        {watchComplete && (
          <div className="flex flex-col my-auto">
            <form className="width-100vw">
              <button
                className="mt-4 mb-4 continue-button animate-pulse border-2 mt-12 text-sm w-3/4 text-white hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg py-3.5 text-center"
                onClick={handleContinueToRiddle}
                >
                {language === 'Spanish' ? 'Continuar al siguiente paso' : 'Continue to Next Step'}
              </button>
              <button 
                className="mt-4 mb-4 continue-button border-2 mt-12 text-xs w-1/2 text-white hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg py-3.5 text-center"
                onClick={handleWatchVideoAgain}
                >
                {language === 'Spanish' ? 'Volver a ver el vídeo (con omisión habilitada)' : 'Re-watch video (skip now enabled)'}
              </button>
            </form>
          </div>
        )}
      </div>
    </div>
  );
}

export default IntroVideo;