import { useLocation } from 'react-router-dom';
import { useUser } from '../../contexts/userContext';
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom'
import Cookies from 'js-cookie'; 
import useOpenAi from '../utils/hooks/useOpenAi'
import { parseResponse } from '../utils/helpers/parsers';
import { createSecondRiddleInstructions, getRandomLoadingMessage, barRiddleInitialPrompt } from '../utils/data'


const SecondRiddle = () =>{
  
  // Hooks
  const { 
    createThreadAndRun, 
    getMessagesAfterRun, 
    getRunStatus, 
    createNewAssistant, 
    createNewMessageOnThread, 
    createRunOnThread, 
    error 
  } = useOpenAi()
  const navigate = useNavigate();
  const location = useLocation();
  const { currentUser, language } = useUser();

  // State Vars
  const [threadId, setThreadId] = useState('')
  const [runQueued, setRunQueued] = useState(true)
  const [runStatusComplete, setRunStatusComplete] = useState(false)
  const [runId, setRunId] = useState(null)
  const [assistantId, setAssistantId] = useState(Cookies.get('assistant_id'))
  const [userMessage, setUserMessage] = useState('')
  const [assistantTextResponse, setAssistantTextResponse] = useState('')
  const [solvedRiddle, setSolvedRiddle] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [runThreadCheckCount, setRunThreadCheckCount] = useState(0)
  const [userSubmitted, setUserSubmitted] = useState(false)


  const messageHistory = location.state?.messageHistory
  const audioTime = location.state?.audioTime
    
  useEffect(() => {
    if(runQueued === true){
      setIsLoading(true)
    } else {
      setIsLoading(false)
    }
  }, [isLoading, runQueued])


  useEffect(() => {
    const audioElement = document.querySelector('audio');
    if (audioElement && audioTime) {
      // console.log('audioElement', audioElement)
      // console.log('audioTime', audioTime)
      // console.log('audioElement.currentTime', audioElement.currentTime)
      // Set the audio element's current time
      audioElement.currentTime = audioTime;
      // Start playback
      audioElement.play();
    }
  }, [audioTime]);


  useEffect(() => {
    const assistantIdFromCookies = Cookies.get('assistant_id')
    if(error){
      console.log('error contacting GPT', error)
      createNewGptAssistant();
    }
    if(!assistantIdFromCookies){
      createNewGptAssistant();
    } 
    
    if(!assistantId || assistantId !== assistantIdFromCookies){
      setAssistantId(assistantIdFromCookies)
    }
    // Auto-trigger the greeting message
    if (assistantId && !threadId) {
      createGreetingMessage();
    }
  }, [assistantId])

  // POLL RUNS AFTER RUNS ARE MADE
  useEffect(() => {
    let intervalId;

    const fetchRunStatus = async () => {
      if (runThreadCheckCount > 12) {
        clearInterval(intervalId); // Stop polling after 9 attempts
        return; // Exit the function
      }

      if (threadId && runId && runQueued) {
        let newValue = runThreadCheckCount + 1
        setRunThreadCheckCount(newValue)
        console.log('runThreadCheckCount', runThreadCheckCount)
        const run = await getRunStatus(threadId, runId);
        console.log('run.data.status', run.data.status)
        if(run) {
          const runStatus = run.data.status;
          if (runStatus === 'failed') {
            resetStateVariables()
          }
          if (runStatus === 'completed') {
            console.log('RUN STATUS COMPLETED 🎉🎉🎉')
            setRunThreadCheckCount(0)
            setRunStatusComplete(true);
            setRunId(null);
            clearInterval(intervalId); // Clear interval when completed
          }
        }
      }
    };
  
    if (threadId && runId && runQueued) {
      intervalId = setInterval(fetchRunStatus, 5000); // Poll every 5 seconds
    }
  
    return () => {
      clearInterval(intervalId); // Clear interval when component unmounts
    };
  }, [threadId, runId, runQueued]);

  // FETCH MESSAGES WHEN RUNS ARE COMPLETE
  useEffect(() => {
    const fetchMessages = async () => {
      if(runStatusComplete && runQueued) {
        const getMessages = await getMessagesAfterRun(threadId)
        if(getMessages) {
          setUserSubmitted(false)
          setRunQueued(false)
          const data = getMessages.data.data[0]
          if(data.role === 'assistant'){
            const messageContent = data.content[0].text.value
            const { parsedContent, error } = parseResponse(messageContent)
            if (error) {
              // console.log('messageContent', messageContent)
              resetStateVariables();
              resetGpt();
            } else {
              // console.log('parsedContent.gpt_response', parsedContent.gpt_response)
              setAssistantTextResponse(parsedContent.gpt_response)
              setSolvedRiddle(parsedContent.correct_answer === true)
            }
          }
        }
      }
    };
  
    fetchMessages();
  }, [runQueued, threadId, runStatusComplete]);

  const createGreetingMessage = async () => {
    const prompt = barRiddleInitialPrompt(currentUser)
    try {
      if(!assistantId){
        console.log('NO ASSISTANT ID')
        return
      }
      const thread = await createThreadAndRun(prompt, assistantId)

      if(thread) {
        const threadIdFromResp = thread.data.thread_id
        const runIdFromResp = thread.data.id

        setThreadId(threadIdFromResp)
        setRunId(runIdFromResp)
        setRunQueued(true)
        setRunStatusComplete(false)
        
      }
    } catch (error) {
      
    }
  }
  
  const createNewGptAssistant = async () => {
    const instructions = createSecondRiddleInstructions(currentUser, messageHistory)
    const newAssistantResp = await createNewAssistant(instructions, currentUser.short_name, 'gpt-3.5-turbo-1106')
    if(newAssistantResp) {
      Cookies.set('assistant_id', newAssistantResp.data.id)
      setAssistantId(newAssistantResp.data.id)
    }
  }

  // HANDLERS
  const handleUserSubmit = async(e) => {
    e.preventDefault()
    setUserSubmitted(true)
    const prompt = `Message from ${currentUser.real_first_name}: ${userMessage}.  NOTE: please follow your instructions, and return only a json like object.`
    const newMessage = await createNewMessageOnThread(prompt, threadId)
    if(newMessage){
      const newRun = await createRunOnThread(threadId, assistantId)
      if(newRun){
        setRunId(newRun.data.id)
        setRunQueued(true)
        setRunStatusComplete(false)
        setUserMessage('')
      }
    }
  }

  const handleMoveToNextStep = (e) => {
    e.preventDefault();
  
    navigate('/vanguardian/claim-your-destiny');
  };
  

  // RESETERS
  const resetStateVariables = (e) => {
    if(e){
      e.preventDefault()
    }
    setRunThreadCheckCount(0)
    setThreadId(null)
    setRunQueued(false)
    setRunStatusComplete(false)
    setRunId(null)
    setAssistantTextResponse('')
    setIsLoading(true)
    setSolvedRiddle(false)
  }

  const resetGpt = (e) => {
    if(e){
      e.preventDefault()
    }
    setAssistantId(null)
    Cookies.remove('assistant_id')
    console.log('assistantId', assistantId)
    console.log('cookies', assistantId)
  }

  // RENDERINGS
  const LoadingSpinner = () => {
    return (
      <div className="flex justify-center items-center text-sm my-auto">
        <svg className="animate-pulse h-5 w-5 text-orange-500" viewBox="0 0 24 24">
          <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
          <path className="opacity-75" fill="currentColor" d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2z"></path>
        </svg>
         {waitMessage()}
      </div>
    );
  };

  const waitMessage = () => {
    if(runThreadCheckCount < 5) {
      return getRandomLoadingMessage(currentUser)
    }else if(runThreadCheckCount > 5 && runThreadCheckCount < 13){
      return "don't even THINK about refreshing"
    } else if(runThreadCheckCount >= 13){
      return "Oop. Got a brainfart.. go ahead and refresh.  Don't worry, the video can be skipped now. 😊"
    }
  }
 
  return (
    <div className="flex flex-col bg-black h-screen justify-center">
      <audio src='https://eclipsed-vanguard.s3.amazonaws.com/audio/ES_Mist+-+Jon+Algar.mp3' style={{ display: 'none' }} autoPlay loop>
        Your browser does not support the audio element.
      </audio>
      {userSubmitted && <audio id="submit-sound" src=' https://eclipsed-vanguard.s3.amazonaws.com/audio/ES_Magic+Wind+Chimes+Harp+-+SFX+Producer.mp3' style={{ display: 'none' }} autoPlay>
        Your browser does not support the audio element.
      </audio>}
      <div className="max-h-37rem mt-12 text-left ml-6 mr-6 font-bold text-gray-700 dark:text-gray-300 overflow-y-auto">
        {isLoading && <LoadingSpinner/>}
        {!isLoading && <h4 className={` text-lg transition-opacity duration-700 fade-in`}>
          {assistantTextResponse}
        </h4>}
      </div>

      {!isLoading && <form className="mt-3 flex flex-col" onSubmit={handleUserSubmit}>
        <div className="justify-center flex mt-10">
          <input 
            onChange={e => setUserMessage(e.target.value)}
            value={userMessage}
            type="text" 
            name="user input"
            id="input" 
            placeholder={language === 'Spanish' ? 'Entra por tu destino...' : "Enter for your destiny..."} 
            className="w-3/4 h-16 bg-gray-50 border text-md border-gray-300 text-gray-900 sm:text-md rounded-lg focus:ring-primary-600 focus:border-primary-600 block p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" 
            required={true}
          />
        </div>
        <button 
          type="submit" 
          className={`${solvedRiddle ? '' : 'animate-pulse'} mb-2.5 text-white mx-auto mt-4 border-2 text-sm w-3/4 bg-primary-600 hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-5xl px-6 py-3.5 text-center dark:bg-primary-800 dark:hover:bg-primary-700 dark:focus:ring-primary-800 mt-2`}
          >
            {solvedRiddle ? `${language === 'Spanish' ? 'Pregúntame más' : 'Ask me more'}` : `${language === 'Spanish' ? 'Pregúntale al guardián de los grandes secretos.': 'Ask the Keeper of Great Secrets'}`}
        </button>
      </form>}

      {solvedRiddle &&
        <button 
        type="submit"
        onClick={handleMoveToNextStep}
        className="animate-pulse mx-auto text-sm w-3/4 border-2 text-white bg-primary-600 hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-5xl px-6 py-3.5 text-center dark:bg-primary-800 dark:hover:bg-primary-700 dark:focus:ring-primary-800 mt-2"
        >
          {language === 'Spanish' ? 'CONTINUAR' : 'CONTINUE'}
      </button>}
    </div>
  )
}

export default SecondRiddle;